<template>
    <div class="edit-permission">
        <transition name="popup">
            <div class="popup-mask" v-if="isShowPopup == true" @click.self="clearFile()">
                <div class="popup-content">
                    <i class="close bi bi-x" @click="clearFile()"></i>
                    <div class="title">{{ $t('filter-str')}}</div>
                    <div class="content">
                        <div class="file-info">
                            <div class="file-detail">
                                <div class="department-filter">
                                    <h6>{{ $t('department-list') }}</h6>
                                    <div class="dept-list">
                                        <ul>
                                            <li v-for="(item, index) in groupList" :key="index" :class="{'item': true, 'selected': item.selected}" 
                                                @click="item.selected = !item.selected">{{ item.dept }}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="private-filter">
                                    <h6>{{ $t('private-option') }}</h6>
                                    <button :class="{ 'button': true, 'is-pref-lang': false }" @click="$emit('change-lang', item)">
                                        {{ $t('owner') }}
                                    </button>
                                    <button :class="{ 'button': true, 'is-pref-lang': false }" @click="$emit('change-lang', item)">
                                        {{ $t('share') }}
                                    </button>
                                </div>
                                <div class="source-filter">
                                    <h6>{{ $t('source-option') }}</h6>
                                    <button :class="{ 'button': true, 'is-pref-lang': false, 'w-25':true }" @click="$emit('change-lang', item)">
                                        {{ $t('web-upload') }}&nbsp;&nbsp;<span class="bi bi-upload"></span>
                                    </button>
                                    <button :class="{ 'button': true, 'is-pref-lang': false, 'w-25':true }" @click="$emit('change-lang', item)">
                                        {{ $t('photocopier') }}&nbsp;&nbsp;<span class="bi bi-printer"></span>
                                    </button>
                                </div>
                                <div class="button-list">
                                    <div class="button cancel-button" @click="clearFile()">
                                        <!-- Cancel -->
                                        {{ $t('reset-button') }}
                                    </div>
                                    <div :class="{ 'button': true, 'disable-button': this.isUploading }"
                                        @click="saveFile(fileData)">
                                        <!-- Save -->
                                        {{ $t('confirm-button') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import api from '../../api/api.js';

export default {
    name: 'editFilter',

  props: {
    filterJson: { type: Object, default: null }
  },

    data() {
        return {
            isShowPopup: true,

            currentGroupList: [],
            privacy: -1,
            source: -1,

            groupList: [],

            selectedGroupDept: '',

            url: '',
            pdfText: '',
            isGroupAiLoading: false,
            isUploading: false,
        };
    },
    methods: {
        removeSameDeptGroup() {
            this.currentGroupList = Object.values(this.currentGroupList.reduce((acc, item) => {
                acc[item.dept] = item;
                return acc;
            }, {}));
        },

        clearFile() {
            this.currentGroupList = [];

            this.closePopup();
        },

        closePopup() {
            this.isShowPopup = false;
            this.$emit('toggleFilterPopup');
        },

        async saveFile() {
            if (this.isUploading) {
                return;
            }
            this.isUploading = true;
            const data = {
                "doc_id": this.doc_id,
                "fileAccessGroups": this.currentGroupList,
            };
            const response = await api.postEditAccessGroup(data);
            if (!response.error) {
                // this.$router.go(0);
                this.isUploading = false;
                this.$emit('refreshList');
                this.closePopup();
            } else {
                console.error(response);
            }
        },

        async getAceessGroupList() {
            const response = await api.getDocumentAceessGroupList();
            if (!response.error && response.data[0].attributes.extra.ricoh) {
                this.groupList = response.data[0].attributes.extra.ricoh.result;

                const uniqueGroups = this.groupList.reduce((acc, item) => {
                    const existingGroup = acc.find(group => group.dept === item.group_dept);
                    if (existingGroup) {
                        existingGroup.lvl.push(item.group_level);
                    } else {
                        acc.push({ dept: item.group_dept, lvl: [item.group_level], selected: false });
                    }
                    return acc;
                }, []);
                this.groupList = uniqueGroups;
            }
        },

        async aiGenerateAccessGroup(prompt = '', retry = 0) {
            if (this.isGroupAiLoading == true) {
                return;
            }
            this.isGroupAiLoading = true;
            let response;
            if (prompt === '') {
                response = await api.postAskAccessGroup("help me to generate some different access group. Reply me a json string only. The json should contain a 'dept' field and a 'lvl' field. The 'dept' field should be a string and the 'lvl' field should be a number. For example, the reply should be like this: [{\"dept\": \"HR\", \"lvl\": 1},{\"dept\": \"HR\", \"lvl\": 2},{\"dept\": \"IT\", \"lvl\": 1}]. The maximuim length of the array is 5. The maximuim lvl is 3. Each item should only has one department and one lvl. Reply me a json string only. Your reply should not contain any other text or information.");
            } else {
                response = await api.postAskAccessGroup(prompt);
            }
            this.isGroupAiLoading = false;
            let result;
            if (!response.error) {
                try {
                    const jsonString = response.data[0].attributes.extra.ricoh.result.content.match(/```json({.*})```|({.*})/s)[0];
                    result = JSON.parse("[" + jsonString + "]")[0];

                }
                catch (error) {
                    console.error('JSON parse error');
                }
            } else {
                console.error(response);
            }
            if (result) {
                const filterResult = this.groupList
                    .filter(item => result.access_departments.includes(item.dept) && item.lvl.includes(parseInt(result.access_level)))
                    .map(item => ({ dept: item.dept, lvl: result.access_level }));
                if (filterResult.length > 0) {
                    filterResult.forEach(newMatch => {
                        const alreadyExists = this.currentGroupList.some(item =>
                            item.dept === newMatch.dept && item.lvl === newMatch.lvl
                        );
                        if (!alreadyExists) {
                            this.currentGroupList.push(newMatch);
                        }
                    });
                    this.removeSameDeptGroup();
                } else {
                    if (retry < 3) {
                        console.log('No new matching access group found. Trying. Automatically regenerating ' + (retry + 1) + '...');
                        this.aiGenerateAccessGroup(prompt, retry + 1);
                    } else {
                        alert('No new matching access group found. You can try to generate again.');
                    }
                }
            }
        },

    },

    mounted() {
        this.getAceessGroupList();
    },

    watch: {
        filterJson: {
            handler(newVal) {
                this.currentGroupList = newVal.groupList;
                this.privacy = newVal.privacy;
                this.source = newVal.source;
            }
        }
    },
}
</script>

<style scoped>
.popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-mask .popup-content {
    position: absolute;
    width: 30%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    border-radius: 30px;
    padding: 20px;
    min-width: 530px;
    max-height: 100%;
    overflow-y: scroll;
}

.popup-mask .popup-content::-webkit-scrollbar {
    display: none;
}

.popup-mask .popup-content .close {
    border-radius: 40px;
    width: 18px;
    font-size: 20px;
    float: right;
    color: gray;
    position: absolute;
    right: 23px;
    top: 13px;
    cursor: pointer;
}

.popup-mask .popup-content .title {
    margin: 20px 0;
    font-weight: bold;
    font-size: 15px;
}

.content .file-detail .button {
    border-radius: 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: #F58A32;
    padding: 0px 10px;
    border: unset;
    width: 20%;
    margin: 5px 5px;
    line-height: 35px;
    font-weight: bold;
}

.content .file-detail .button:hover {
    background-color: #F05A27;
}

.content .file-detail .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .button.disable-button {
    background-color: darkgray;
    cursor: default;
}

.content .file-detail .button.cancel-button {
    background-color: transparent;
    color: gray;
}

.content .file-info {
    width: 100%;
    position: relative;
    padding: 0 10px;
}

.content .file-detail h6 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.content .file-detail .file-detail-permission {
    margin-top: 10px;
    text-align: left;
    font-size: 12px;
    background-color: #F7F7F7;
    padding: 10px;
    border-radius: 10px 10px 0 0;
}

.content .file-detail .button-list {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.loading {
    position: relative;
    width: 20px;
    height: 20px;
}

.tip {
    position: absolute;
    background-color: #DDDDDD;
    color: #666666;
    padding: 2px 10px;
    border-radius: 25px;
    z-index: 1000;
    font-size: 14px;
    max-width: 30%;
    text-align: left;
}

.tip:before {
    width: 0px;
    height: 0px;
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #DDDDDD;
    position: absolute;
    top: -10px;
    left: 50px;
}

.department-filter {
        border-bottom: 1px solid gray;
}

.department-filter h6 {
        text-align: left;
}

.department-filter .dept-list {
    margin-top: 20px;
}

.department-filter .dept-list ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.department-filter .dept-list ul li {
    list-style-type: none;
    background-color: white;
    margin: 0px 8px 5px 0px;
    border-radius: 20px;
    padding: 0 15px;
    color: #F05A27;
    border: 1px solid #F05A27;
    font-size: 12px;
    font-weight: bold;
}

.department-filter .dept-list ul li.selected {
    background-color: peachpuff;
}

.private-filter {
    border-bottom: 1px solid gray;
    padding-top: 1rem;
}

.private-filter h6 {
    text-align: left;
}

.source-filter {
    padding-top: 1rem;
}

.source-filter h6 {
        text-align: left;
}



</style>