<template>
    <div class="upload-document">
        <transition name="popup">
            <div class="popup-mask" v-if="isShowPopup == true">
                <div class="popup-content">
                    <div class="tab" v-show="Object.getOwnPropertyNames(fileData).length == 0">
                        <div class="tablinks-container">
                            <button :class="activeTab === 'uploadFile' ? 'active-tablinks' : 'tablinks'" @click="() => {activeTab = 'uploadFile'}"><i class="bi bi-file-text"></i> {{ $t('btn-upload-new') }}</button>
                            <button :class="activeTab === 'uploadFileBatch' ? 'active-tablinks' : 'tablinks'" @click="() => {activeTab = 'uploadFileBatch'}"><i class="bi bi-files"></i> {{ $t('batch-upload') }}</button>
                        </div>
                        <i class="tab-close bi bi-x" @click="clearFile()"></i>
                    </div>
                    <div id="uploadFile" class="tabcontent" v-show="activeTab === 'uploadFile'">
                        <div class="content">
                            <div ref="drag" class="drag" v-show="Object.getOwnPropertyNames(fileData).length == 0">
                                <div class="drag-box">
                                    <div class="drag-icon-box">
                                        <img src="../../assets/upload_icon.png" alt="upload">
                                    </div>
                                    <div class="drag-message">
                                        <!-- <span class="drag-message-title">Drag and drop a file here</span> -->
                                        <span class="drag-message-title">{{ $t('drag-file-here') }}</span>
                                        <!-- <p>or</p> -->
                                        <p>{{ $t('or') }}</p>
                                        <label class="drag-message-label">
                                            <input id="upload-bar-input" type="file"
                                                accept="application/pdf,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                style="display: none;" @change="onFileChanged()">
                                            <label for="upload-bar-input">
                                                <!-- <div class="drag-message-manual">Select a file</div> -->
                                                <div class="drag-message-manual">{{ $t('select-a-file') }}</div>
                                            </label>
                                        </label>
                                        <!-- <p>(supports:.pdf,.docx,.txt)</p> -->
                                        <p>{{ $t('support-msg') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div v-show="Object.getOwnPropertyNames(fileData).length != 0" class="file-info">
                                <div class="file-detail">
                                    <!-- <div id="upload-bar-preview" class="upload-bar-preview">Empty File Input</div> -->
                                    <div class="upload-bar-preview">{{message}}</div>
                                    <div v-show="isTitleAiLoading || isGroupAiLoading || isUploading"
                                        class="generate-loader"></div>
                                    <div v-show="!isTitleAiLoading && !isGroupAiLoading && !isUploading">
                                        <div class="file-detail-privacy">
                                            <!-- <h6>Set to private</h6> -->
                                            <h6>{{ $t('set-to-private') }}</h6>
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox"
                                                    id="file-detail-privacy-input" v-model="privateFlag">
                                            </div>
                                        </div>
                                        <div class="document-summary">
                                            <div class="ai-generate">
                                                <!-- <p>Document Summary</p> -->
                                                <p>{{ $t('file-summary') }}</p>
                                                <!-- <p>AI-Recommended Title and Description</p> -->
                                                <button class="ai-generate-button"
                                                    @click="aiGenerateTitleDescription(this.uploadText); isShowTitleHint = false;"
                                                    @mouseover="showTitleTip" @mouseout="isShowTitleHint = false">
                                                    <!-- <div v-if="!isTitleAiLoading"><i class="bi bi-star-fill"></i>&nbsp;&nbsp;&nbsp;AI suggest</div> -->
                                                    <div v-if="!isTitleAiLoading">
                                                        <i class="bi bi-star-fill"></i>&nbsp;&nbsp;&nbsp;{{ $t('ai-suggest') }}
                                                    </div>
                                                    <div v-if="isTitleAiLoading" class="loading-box">
                                                        <div class="loading"></div>{{ $t('ai-loading') }}
                                                    </div>
                                                </button>
                                            </div>
                                            <div class="file-detail-title">
                                                <!-- <h6>Title:</h6> -->
                                                <h6>{{ $t('title') }}:</h6>
                                                <input :class="{ 'animated-background': isTitleAiLoading }" type="text"
                                                    v-model="fileTitle" :disabled="isTitleAiLoading" />
                                            </div>
                                            <div class="file-detail-description">
                                                <!-- <h6>Description:</h6> -->
                                                <h6>{{ $t('description') }}:</h6>
                                                <div
                                                    :class="{ 'file-detail-description-textarea': true, 'animated-background': isTitleAiLoading }">
                                                    <textarea class="scrollbar" rows="1" ref="descriptionTextarea"
                                                        v-model="fileDescription" @input="adjustHeight"
                                                        onpropertychange="this.style.posHeight = this.scrollHeight"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="!privateFlag">
                                            <div class="file-detail-permission">
                                                <!-- <h6>Permission List</h6> -->
                                                <h6>{{ $t('permission-list') }}</h6>
                                                <div class="ai-generate">
                                                    <p></p>
                                                    <!-- <p>AI-recommended permission levels</p> -->
                                                    <div class="ai-generate">
                                                        <i class="bi bi-info-circle reason-more"
                                                            :class="{ active: isReasonShow }"
                                                            @click="isReasonShow = !isReasonShow"></i>
                                                        <button class="ai-generate-button"
                                                            @click="aiGenerateAccessGroup(this.uploadText); isShowGroupHint = false;"
                                                            @mouseover="showGroupTip" @mouseout="isShowGroupHint = false">
                                                            <!-- <div v-if="!isGroupAiLoading"><i class="bi bi-star-fill"></i>&nbsp;&nbsp;&nbsp;AI suggest</div> -->
                                                            <div v-if="!isGroupAiLoading">
                                                                <i class="bi bi-star-fill"></i>&nbsp;&nbsp;&nbsp;{{ $t('ai-suggest') }}
                                                            </div>
                                                            <div v-if="isGroupAiLoading" class="loading-box">
                                                                <div class="loading"></div>{{ $t('ai-loading') }}
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div v-if="isReasonShow"
                                                    :class="{ 'ai-permission-reason-outer': true, 'reason-loading': isGroupAiLoading || !permissionReason }">
                                                    <div class="ai-permission-reason scrollbar">{{ permissionReason }}</div>
                                                </div>
                                                <div class="flex-space-between">
                                                    <div>
                                                        <select id="group-dept" v-model="selectedGroupDept"
                                                            @mouseover="showDepartmentTip" @mouseout="isShowDepartmentHint = false"
                                                            :disabled="isGroupAiLoading"
                                                            :class="{ 'animated-background': isGroupAiLoading }">
                                                            <option value='' disabled selected>( {{ $t('select-department') }} )</option>
                                                            <option v-for="group in groupList" :key="group.id">{{ group.dept
                                                            }}
                                                            </option>
                                                        </select>
                                                        <select id="group-level" v-model="selectedGroupLevel" v-show="selectedGroupDept != ''" 
                                                            @mouseover="showLevelTip" @mouseout="isShowLevelHint = false"
                                                            :disabled="isGroupAiLoading"
                                                            :class="{ 'animated-background': isGroupAiLoading }">
                                                            <option value='' disabled selected>( {{ $t('select-level') }} )</option>
                                                            <option v-for="level in availableGroupLevels" :key="level"
                                                                :value="level">
                                                                <span v-if="level == 4">C-Level</span>
                                                                <span v-else>Level  {{ level }}</span>
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <button
                                                        :class="{ 'button': true, 'add-button': true, 'disable-button-colored': isGroupAiLoading, 'animated-background': isGroupAiLoading }"
                                                        @click="addPermission()" :disabled="isGroupAiLoading">
                                                        <div v-if="!isGroupAiLoading">+</div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="file-detail-perimission-list">
                                                <!-- <h6>Current Permission List:</h6> -->
                                                <h6>{{ $t('current-permission-list') }}:</h6>
                                                <p v-if="currentGroupList.length === 0">{{ $t('no-permission-msg') }}</p>
                                                <ul v-else>
                                                    <li class="item" v-for="item in currentGroupList" :key="item.id">
                                                        <span v-if="item.lvl === 4">{{ item.dept }} C-Level</span>
                                                        <span v-else>{{ item.dept }} Level {{ item.lvl }} </span>
                                                        <i class="bi bi-x-lg" @click="removePermission(item)"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="button-list">
                                            <div class="button cancel-button" @click="clearFile()">
                                                <!-- Cancel -->
                                                {{ $t('cancel') }}
                                            </div>
                                            <div :class="{ 'button': true, 'disable-button': this.isUploading || this.isTitleAiLoading || this.isGroupAiLoading }"
                                                @click="submitFile(fileData)">
                                                <!-- Save -->
                                                {{ $t('save') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="uploadFileBatch" class="tabcontent" v-show="activeTab === 'uploadFileBatch'">
                        <div class="content">
                            <div class="queue-item-bar">
                                <div v-show="Object.getOwnPropertyNames(fileData).length == 0" class="upload-bar-preview">
                                    {{message}}
                                </div>
                                <div class="queue-item-bar-title">
                                    {{ $t('upload-queue') }}
                                </div>
                                <div class="queue-item-bar-body">
                                    <span class="queue-item-bar-count" :style="{width: (queueItemCount * 10) + '%'}" ></span>
                                    {{ $t('queue-count') }}:{{ this.queueItemCount }}
                                </div>
                            </div>
                            <div ref="dragBatch" class="drag" v-show="Object.getOwnPropertyNames(fileData).length == 0">
                                <div class="drag-box">
                                    <div class="drag-icon-box">
                                        <img src="../../assets/upload_icon.png" alt="upload">
                                    </div>
                                    <div class="drag-message">
                                        <!-- <span class="drag-message-title">Drag and drop a file here</span> -->
                                        <span class="drag-message-title">{{ $t('drag-multiple-files-here') }}</span>
                                        <!-- <p>or</p> -->
                                        <p>{{ $t('or') }}</p>
                                        <label class="drag-message-label">
                                            <input id="upload-bar-input-batch" type="file" multiple
                                                accept="application/pdf,text/plain,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                style="display: none;" @change="onFileChanged()">
                                            <label for="upload-bar-input-batch">
                                                <!-- <div class="drag-message-manual">Select a file</div> -->
                                                <div class="drag-message-manual">{{ $t('select-multiple-files') }}</div>
                                            </label>
                                        </label>
                                        <!-- <p>(supports:.pdf,.docx,.txt)</p> -->
                                        <p>{{ $t('support-msg') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div v-show="Object.getOwnPropertyNames(fileData).length != 0" class="file-info">
                                <div class="file-detail">
                                    <div class="upload-bar-preview">{{message}}</div>
                                    <div v-show="isTitleAiLoading || isGroupAiLoading || isUploading"
                                        class="generate-loader"></div>
                                    <div v-show="!isTitleAiLoading && !isGroupAiLoading && !isUploading" class="button batch-button" @click="clearFile()">確定</div>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <!-- <div class="title">Upload new document</div> -->
                </div>
                <div v-if="isShowTitleHint" class="tip" :style="titleHintPos">
                    <!-- Generate title and description Recommendations -->
                    {{ $t('generate-title-suggestion') }}
                </div>
                <div v-if="isShowGroupHint" class="tip" :style="titleHintPos">
                    <!-- Generate permission levels Recommendations -->
                    {{ $t('generate-permission-suggestion') }}
                </div>
                <div v-if="isShowDepartmentHint" class="tip" :style="titleHintPos">
                    <!-- Department Hints -->
                    {{ $t('permission-department-description') }}
                </div>
                <div v-if="isShowLevelHint" class="tip" :style="titleHintPos">
                    <!-- Access Level Hints -->
                    <p>{{ $t('permission-level-1-description') }}</p>
                    <p>{{ $t('permission-level-2-description') }}</p>
                    <p>{{ $t('permission-level-3-description') }}</p>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import api from '../../api/api.js';
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import pdfjsWorker from 'pdfjs-dist/legacy/build/pdf.worker.entry';
import mammoth from 'mammoth';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
    name: 'uploadDocument',
    props: {
        userEmail: String
    },
    data() {
        return {
            isShowPopup: true,
            groupList: [],
            dragFile: {},
            dragFileBatch: {},

            fileData: {},
            fileName: '',
            mediaTypes: '',
            fileContent: '',
            fileTitle: '',
            fileDescription: '',
            privateFlag: false,
            currentGroupList: [],
            permissionReason: '',

            uploadText: '',

            message: this.$t('ask-multiple-files'),
            inputBox: null,
            inputBoxBatch: null,

            selectedGroupDept: '',
            selectedGroupLevel: '',

            isTitleAiLoading: false,
            isGroupAiLoading: false,
            isUploading: false,
            isReasonShow: false,

            mouseX: 0,
            mouseY: 0,
            isShowTitleHint: false,
            titleHintPos: {
                top: '0px',
                left: '0px'
            },
            isShowGroupHint: false,
            isShowDepartmentHint: false,
            isShowLevelHint: false,

            minHeight: 31,

            activeTab: 'uploadFile',

            queueItemCount: null,

            alertEmptyTitle: this.$t('alert-empty-title'),
            alertSelectGroup: this.$t('alert-select-group'),
        };
    },
    methods: {
        onFileChanged() {
            const filesBatch =   this.inputBoxBatch.files && this.inputBoxBatch.files.length > 0 ? this.inputBoxBatch :
                            this.dragFileBatch.files && this.dragFileBatch.files.length > 0 ? this.dragFileBatch : null;
            const files = this.inputBox.files && this.inputBox.files.length > 0 ? this.inputBox :
                            this.dragFile.files && this.dragFile.files.length > 0 ? this.dragFile : null;
            if (filesBatch) {
                if (filesBatch.files.length == 1) {
                    this.message = this.$t('ask-multiple-files');
                    this.fileData = {};
                    return;
                } else if (!this.checkFileSize(filesBatch)) {
                    this.message = this.$t('max-size-20mb');
                    this.fileData = {};
                    return;
                } else if(filesBatch.files.length + this.queueItemCount > 10) {
                    this.message = this.$t('exceed-file-limit');
                    this.fileData = {};
                    return;
                } else {
                    const filesName = [];
                    for (const file of filesBatch.files){
                        filesName.push(file.name)
                    }
                    this.message = filesName.join("\n");
                    this.onSelectChange(filesBatch);
                }
            } else if (files) {
                if (!this.checkFileSize(files)) {
                    this.message = this.$t('max-size-20mb');
                    this.fileData = {};
                    return;
                } else {
                    const filesName = [];
                    for (const file of files.files){
                        filesName.push(file.name)
                    }
                    this.message = filesName.join("\n");
                    this.onSelectChange(files);
                }
            } else {
                this.message = this.$t('no-file-selected');
                this.fileData = {};
            }
        },

        checkFileSize(input) {
            if (input.files) {
                let oversizeFiles = [];
                for (const file of input.files){
                    var fileSize = file.size;
                    var maxSize = 1024 * 1024 * 20; // 20MB
    
                    if (fileSize > maxSize) {
                        oversizeFiles.push(file.name)
                    }
                }
                if (oversizeFiles.length > 0) {
                    alert(`${oversizeFiles.join("\n")} ` + this.$t('size-exceed-20mb'));
                    input.value = "";
                    return false;
                } else {
                    return true;
                }
            }
        },

        async onSelectChange(ev) {
            if (ev.files.length > 0) {
                const filesLength = ev.files.length;
                const multipleFiles = filesLength > 1;
                const uploadFiles = []
                for (const file of ev.files){
                    this.fileName = file.name;
                    const mediaTypesTemp = file.type;
                    let uploadTextTemp = '';
                    if (!["application/pdf", "text/plain", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(mediaTypesTemp)){
                        this.message = this.$t('filetype-not-supported');
                        return;
                    }
                    if (!multipleFiles){
                        if (mediaTypesTemp === "application/pdf") {
                            this.extractTextFromPdf(file);
                        } else if (mediaTypesTemp === "text/plain") {
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                uploadTextTemp = e.target.result;
                                this.aiGenerateTitleDescription(uploadTextTemp);
                                this.aiGenerateAccessGroup(uploadTextTemp);
                            };
                            reader.readAsText(file);
                        } else if (mediaTypesTemp === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                            const reader = new FileReader();
                            reader.onload = async (e) => {
                                const arrayBuffer = e.target.result;
                                const result = await mammoth.extractRawText({ arrayBuffer });
                                uploadTextTemp = result.value;
                                this.aiGenerateTitleDescription(uploadTextTemp);
                                this.aiGenerateAccessGroup(uploadTextTemp);
                            };
                            reader.readAsArrayBuffer(file);
                        } else {
                            this.mediaTypes = '';
                            this.message = this.$t('filetype-not-supported');
                            return;
                        }
                    }

                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onloadend = async (rev) => {
                        const fileContentTemp = rev.target.result.replace('data:', '').replace(/^.+,/, '');
                        const data = {
                            "name": this.fileName,
                            "mediatypes": this.mediaTypes,
                            "content": this.fileContent
                        };
                        this.fileData = data;
                        this.inputBox.value = '';
                        this.inputBoxBatch.value = '';
                        if (multipleFiles) {
                            uploadFiles.push(api.postQueueUpload({
                                "base64Content": fileContentTemp,
                                "fileType": file.type,
                                "fileName": file.name,
                            }));
                            if (uploadFiles.length === filesLength) {
                                this.isUploading = true;
                                const results = await Promise.all(uploadFiles);
                                this.isUploading = false;
                                if (results && results.length > 0 && results.every((res) => res && res.data[0].attributes.extra.ricoh.result.msg === "Success")) {
                                    this.message = this.$t('upload-file-success');
                                    this.$emit('refreshList');
                                } else {
                                    this.message = this.$t('upload-file-fail');
                                }
                                this.getQueueItemCount();
                            }
                        } else {
                            this.mediaTypes = mediaTypesTemp;
                            this.uploadText = uploadTextTemp;
                            this.fileContent = fileContentTemp;
                        }
                    };
                }
            } else {
                console.log(this.$t('no-file-selected'));
            }
        },

        addPermission() {
            if (this.selectedGroupDept && this.selectedGroupLevel) {
                const alreadyExists = this.currentGroupList.some(item =>
                    item.dept === this.selectedGroupDept && item.lvl === this.selectedGroupLevel
                );
                if (!alreadyExists) {
                    this.currentGroupList.push({
                        dept: this.selectedGroupDept,
                        lvl: this.selectedGroupLevel,
                    })
                }
            }
            this.removeSameDeptGroup();
        },

        removeSameDeptGroup() {
            this.currentGroupList = Object.values(this.currentGroupList.reduce((acc, item) => {
                acc[item.dept] = item;
                return acc;
            }, {}));
        },

        removePermission(item) {
            this.currentGroupList = this.currentGroupList.filter(i => i !== item);
        },

        clearFile() {
            this.fileData = {};
            this.fileContent = '';
            this.fileName = '';
            this.mediaTypes = '';
            this.fileTitle = '';
            this.fileDescription = '';
            this.currentGroupList = [];
            this.selectedGroupDept = '';
            this.selectedGroupLevel = '';
            this.privateFlag = false;
            this.inputBox.value = '';
            this.inputBoxBatch.value = '';
            this.uploadText = '';
            this.permissionReason = '';
            this.message = this.$t('no-file-selected');

            this.closePopup();
        },

        closePopup() {
            this.isShowPopup = false;
            this.$emit('toggleUploadPopup');
        },

        async submitFile() {
            if (!this.fileTitle) {
                alert(this.alertEmptyTitle);
                return;
            }
            if (!this.privateFlag && this.currentGroupList.length == 0) {
                alert(this.alertSelectGroup);
                return;
            }
            if (this.isUploading) {
                return;
            }
            this.isUploading = true;
            const data = {
                "base64Content": this.fileContent,
                "fileType": this.mediaTypes,
                "fileName": this.fileName,
                "fileTitle": this.fileTitle,
                "fileDescription": this.fileDescription,
                "fileAccessGroups": this.privateFlag ? [] : this.currentGroupList,
                "privateFlag": this.privateFlag ? 1 : 0,
            };
            const response = await api.postUploadFile(data);
            if (!response.error) {
                // this.$router.go(0);
                this.isUploading = false;
                this.$emit('refreshList');
                this.closePopup();
            } else {
                console.error(response);
            }
        },

        bindEvents() {
            for (const drag of [this.$refs.drag,this.$refs.dragBatch]){
                drag.addEventListener('dragover', e => {
                    e.preventDefault();
                    drag.style.borderColor = '#F58A32';
                })
                drag.addEventListener('dragleave', e => {
                    e.preventDefault();
                    drag.style.borderColor = '#a3a3a3';
                })
                
            }
            this.$refs.drag.addEventListener('drop', e => {
                e.preventDefault();
                this.$refs.drag.style.borderColor = '#a3a3a3';
                this.dragFile = e.dataTransfer;
                this.onFileChanged();
            })
            this.$refs.dragBatch.addEventListener('drop', e => {
                e.preventDefault();
                this.$refs.dragBatch.style.borderColor = '#a3a3a3';
                this.dragFileBatch = e.dataTransfer;
                this.onFileChanged();
            })
        },

        async getAceessGroupList() {
            const response = await api.getDocumentAceessGroupList();
            if (!response.error && response.data[0].attributes.extra.ricoh) {
                this.groupList = response.data[0].attributes.extra.ricoh.result;

                const uniqueGroups = this.groupList.reduce((acc, item) => {
                    const existingGroup = acc.find(group => group.dept === item.group_dept);
                    if (existingGroup) {
                        existingGroup.lvl.push(item.group_level);
                    } else {
                        acc.push({ dept: item.group_dept, lvl: [item.group_level] });
                    }
                    return acc;
                }, []);
                this.groupList = uniqueGroups;
            }
        },

        async aiGenerateTitleDescription(prompt = '') {
            if (this.isTitleAiLoading == true) {
                return;
            }
            this.isTitleAiLoading = true;
            let response;
            if (prompt === '') {
                response = await api.postAskTitleDescription("help me to generate another title and some realtive description, reply me a json string only");
            } else {
                response = await api.postAskTitleDescription(prompt);
            }
            this.isTitleAiLoading = false;
            let result;
            if (!response.error) {
                try {
                    // result = JSON.parse("["+response.data[0].attributes.extra.ricoh.result.content.replace(/'/g, '"')+"]")[0];
                    const jsonString = response.data[0].attributes.extra.ricoh.result.content.match(/```json({.*})```|({.*})/s)[0];
                    result = JSON.parse("[" + jsonString + "]")[0];
                }
                catch (error) {
                    console.error('JSON parse error');
                }
            } else {
                console.error(response);
            }
            if (result) {
                if (result.title) this.fileTitle = result.title;
                if (result.description) {
                    this.fileDescription = result.description;
                    this.$nextTick(() => {
                        this.adjustHeight();
                    });
                }
            } else {
                alert(this.$t('fail-ai-title'));
            }
        },

        async aiGenerateAccessGroup(prompt = '', retry = 0) {
            if (this.isGroupAiLoading == true) {
                return;
            }
            this.isGroupAiLoading = true;
            this.permissionReason = '';
            let response;
            if (prompt === '') {
                response = await api.postAskAccessGroup("help me to generate some different access group. Reply me a json string only. The json should contain a 'dept' field and a 'lvl' field. The 'dept' field should be a string and the 'lvl' field should be a number. For example, the reply should be like this: [{\"dept\": \"HR\", \"lvl\": 1},{\"dept\": \"HR\", \"lvl\": 2},{\"dept\": \"IT\", \"lvl\": 1}]. The maximuim length of the array is 5. The maximuim lvl is 3. Each item should only has one department and one lvl. Reply me a json string only. Your reply should not contain any other text or information.");
            } else {
                response = await api.postAskAccessGroup(prompt);
            }
            this.isGroupAiLoading = false;
            let result;
            if (!response.error) {
                try {
                    // result = JSON.parse(response.data[0].attributes.extra.ricoh.result.content.replace(/'/g, '"'));
                    const jsonString = response.data[0].attributes.extra.ricoh.result.content.match(/```json({.*})```|({.*})/s)[0];
                    result = JSON.parse("[" + jsonString + "]")[0];
                }
                catch (error) {
                    console.error('JSON parse error');
                }
            } else {
                console.error(response);
            }
            if (result) {
                const filterResult = this.groupList
                    .filter(item => result.access_departments.includes(item.dept) && item.lvl.includes(parseInt(result.access_level)))
                    .map(item => ({ dept: item.dept, lvl: result.access_level }));
                if (filterResult.length > 0) {
                    filterResult.forEach(newMatch => {
                        const alreadyExists = this.currentGroupList.some(item =>
                            item.dept === newMatch.dept && item.lvl === newMatch.lvl
                        );
                        if (!alreadyExists) {
                            this.currentGroupList.push(newMatch);
                        }
                    });
                    this.removeSameDeptGroup();
                    this.permissionReason = result.reason;
                } else {
                    if (retry < 3) {
                        console.log('No new matching access group found. Trying. Automatically regenerating ' + (retry + 1) + '...');
                        this.aiGenerateAccessGroup(prompt, retry + 1);
                    } else {
                        alert(this.$t('no-match-group'));
                    }
                }
            }
        },

        async extractTextFromPdf(file) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const arrayBuffer = e.target.result;
                const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
                let textContent = '';

                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const content = await page.getTextContent();
                    content.items.forEach(item => {
                        textContent += item.str + ' ';
                    });
                }

                this.uploadText = textContent.trim();
                this.aiGenerateTitleDescription(this.uploadText);
                this.aiGenerateAccessGroup(this.uploadText);
            };
            reader.readAsArrayBuffer(file);
        },

        getMousePosition(event) {
            this.mouseX = event.clientX;
            this.mouseY = event.clientY;
        },

        showTitleTip(event) {
            this.isShowTitleHint = true;
            this.titleHintPos.top = `${event.clientY + 25}px`;
            this.titleHintPos.left = `${event.clientX - 40}px`;
        },

        showGroupTip(event) {
            this.isShowGroupHint = true;
            this.titleHintPos.top = `${event.clientY + 25}px`;
            this.titleHintPos.left = `${event.clientX - 40}px`;
        },

        showDepartmentTip(event) {
            this.isShowDepartmentHint = true;
            this.titleHintPos.top = `${event.clientY + 25}px`;
            this.titleHintPos.left = `${event.clientX - 40}px`;
        },

        showLevelTip(event) {
            this.isShowLevelHint = true;
            this.titleHintPos.top = `${event.clientY + 25}px`;
            this.titleHintPos.left = `${event.clientX - 40}px`;
        },

        adjustHeight() {
            const textarea = this.$refs.descriptionTextarea;
            const lineHeight = parseInt(getComputedStyle(textarea).lineHeight); // ~21 min=31
            const maxHeight = lineHeight * 3 + 10;

            this.$refs.descriptionTextarea.style.height = 'auto';
            if (this.$refs.descriptionTextarea.scrollHeight <= maxHeight) {
                this.$refs.descriptionTextarea.style.height = this.$refs.descriptionTextarea.scrollHeight + 'px';
            } else {
                this.$refs.descriptionTextarea.style.height = maxHeight + 'px';
            }
        },

        checkLoading() {
            if (!this.isTitleAiLoading && !this.isGroupAiLoading) {
                this.$nextTick(() => {
                    this.adjustHeight();
                })
            }
        },

        async getQueueItemCount() {
            const response = await api.getbatchuploadQueue();
            if (!response.error && response.data[0].attributes.extra.ricoh) {
                this.queueItemCount = response.data[0].attributes.extra.ricoh.result.batchUploadQueueList.total;
            }
        }
    },

    computed: {
        availableGroupLevels() {
            if (this.selectedGroupDept !== '') {
                const selectedGroup = this.groupList.find(
                    (group) => group.dept === this.selectedGroupDept
                );
                // selectedGroup.lvl.push(4);
                return selectedGroup ? selectedGroup.lvl : [];
            }
            return [];
        },
    },

    watch: {
        isTitleAiLoading: 'checkLoading',
        isGroupAiLoading: 'checkLoading',
    },

    mounted() {
        this.bindEvents();
        this.inputBox = document.querySelector("#upload-bar-input");
        this.inputBoxBatch = document.querySelector("#upload-bar-input-batch");
        this.getAceessGroupList();
        this.getQueueItemCount();
    }
}
</script>

<style scoped>
.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.popup-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.popup-mask .popup-content {
    position: absolute;
    width: 30%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
    z-index: 1000;
    border-radius: 30px;
    padding: 20px;
    min-width: 650px;
    max-height: 100%;
    overflow-y: scroll;
}

.popup-mask .popup-content::-webkit-scrollbar {
    display: none;
}

.popup-mask .popup-content .close {
    border-radius: 40px;
    width: 18px;
    font-size: 20px;
    float: right;
    color: gray;
    position: absolute;
    right: 23px;
    top: 13px;
    cursor: pointer;
}

.popup-mask .popup-content .title {
    margin: 20px 0;
    font-weight: bold;
    font-size: 15px;
}

.drag {
    height: 150px;
    border: 2px dashed;
    border-color: #a3a3a3;
    border-radius: 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: lightgray;
}

.drag .drag-box {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}

.drag .drag-icon-box {
    height: 60px;
    text-align: center;
    font-size: 50px;
    line-height: 60px;
    color: #606266;
}

.drag .drag-icon-box img {
    width: 65px;
}

.drag .drag-message {
    min-inline-size: fit-content;
    line-height: 20px;
    text-align: center;
}

.drag .drag-message .drag-message-title {
    font-size: 14px;
    color: #606266;
    font-weight: bold;
}

.drag .drag-message p {
    margin: 0;
    font-weight: lighter;
    font-size: 14px;
}

.drag .drag-message .drag-message-label {
    height: auto;
    position: relative;
    overflow: hidden;
}

.drag .drag-message .drag-message-label .drag-message-input {
    position: absolute;
    left: -100px;
    top: -100px;
    z-index: -1;
    display: none;
}

.drag .drag-message .drag-message-label .drag-message-manual {
    border-radius: 10px;
    font-size: 14px;
    color: #F58A32;
    cursor: pointer;
    padding: 0 20px;
    font-weight: bold;
}

.upload-bar-preview {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: red;
    white-space: pre-line;
}

.content .file-detail .button {
    border-radius: 20px;
    font-size: 14px;
    color: white;
    cursor: pointer;
    background-color: #F58A32;
    padding: 0px 10px;
    border: unset;
    width: 15%;
    margin: 5px 5px;
    line-height: 35px;
    font-weight: bold;

    &.batch-button {
        margin: 5px auto;
        line-height: normal;
    }
}

.content .file-detail .button:hover {
    background-color: #F05A27;
}

.content .file-detail .button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .button.disable-button {
    background-color: darkgray;
    cursor: default;
}

.content .file-detail .button.cancel-button {
    background-color: transparent;
    color: gray;
}

.content .file-info {
    width: 100%;
    position: relative;
    padding: 0 10px;
}

.content .file-detail h6 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: bold;
}

.content .file-detail .file-detail-title {
    text-align: left;
}

.content .file-detail .file-detail-title input {
    width: 100%;
    border: unset;
    outline: unset;
    border-bottom: 1px solid gray;
    padding: 10px 15px;
    font-size: 14px;
    background-color: transparent;
}

.content .file-detail .file-detail-description {
    margin-top: 15px;
    text-align: left;
}

.content .file-detail .file-detail-description .file-detail-description-textarea {
    width: 100%;
    border-bottom: 1px solid gray;
    padding: 5px 0px;
    font-size: 14px;
    display: flex;
}

.content .file-detail .file-detail-description .file-detail-description-textarea textarea {
    width: 100%;
    border: none;
    resize: none;
    outline: none;
    padding: 5px 15px;
    align-content: end;
    background-color: transparent;
}

.content .file-detail .file-detail-privacy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 10px 0;
    padding: 10px;
    background-color: #F7F7F7;
    border-radius: 10px;
}

.content .file-detail .file-detail-privacy .form-switch .form-check-input {
    box-shadow: unset;
    border: 1px solid gray;
    background-color: gray;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e")
}

.content .file-detail .file-detail-privacy .form-switch .form-check-input:checked {
    border: 1px solid #F58A32;
    background-color: #F58A32;
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%28255, 255, 255, 1%29%27/%3e%3c/svg%3e")
}

.content .file-detail .file-detail-permission {
    margin-top: 10px;
    text-align: left;
    font-size: 12px;
    background-color: #F7F7F7;
    padding: 10px;
    border-radius: 10px 10px 0 0;
}

.content .file-detail .file-detail-permission .ai-permission-reason-outer {
    margin: 4px 0 12px 0;
    background: transparent;
    border-bottom: 1px solid gray;
    padding: 5px 15px;
}

.content .file-detail .file-detail-permission .ai-permission-reason-outer.reason-loading {
    min-height: 4.2em;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right,
            #71AAE7,
            #FA9539,
            #C4E2FE);
    background-size: 500% 100%;
    animation: colorChange 5s ease infinite;
}

.content .file-detail .file-detail-permission .ai-permission-reason {
    overflow-y: scroll;
    max-height: 4.2em;
    line-height: 1.4em;
}

.content .file-detail .file-detail-permission select {
    margin: 0 10px 0 0;
    min-width: 150px;
    border: 1px solid gray;
    border-radius: 15px;
    height: 1.5rem;
    padding: 0px 10px;
}

.content .file-detail .file-detail-permission .add-button {
    padding: 0;
    width: 115px;
    margin: 0;
    line-height: normal;
    border-radius: 20px;
}

.content .file-detail .file-detail-permission .add-button.disable-button-colored {
    cursor: default;
}

.content .file-detail .file-detail-permission .reason-more {
    margin: 0 5px;
    cursor: pointer;
    color: #919191;
    font-size: 19px;
}

.content .file-detail .file-detail-permission .reason-more.active {
    color: dodgerblue;
}

.content .file-detail .file-detail-perimission-list {
    text-align: left;
    background-color: #F7F7F7;
    padding: 10px;
    border-radius: 0 0 10px 10px;
}

.content .file-detail .file-detail-perimission-list ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.content .file-detail .file-detail-perimission-list ul li {
    list-style-type: none;
    background-color: peachpuff;
    margin: 0 5px 5px 5px;
    border-radius: 20px;
    padding: 0 15px;
    color: #F05A27;
    border: 1px solid #F05A27;
    font-size: 12px;
    font-weight: bold;
}

.content .file-detail .file-detail-perimission-list ul li i {
    margin-left: 5px;
    color: #FFFFFF;
    cursor: pointer;
}

.content .file-detail .button-list {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.content .file-detail .ai-generate {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    flex-wrap: wrap;
    align-items: center;
}

.content .file-detail .ai-generate p {
    margin: 0;
    text-align: left;
    font-size: 12px;
    width: 60%;
    font-weight: bold;
    color: gray;
}

.content .file-detail .ai-generate .ai-generate-button {
    border-radius: 20px;
    background: linear-gradient(to left, #71AAE7, #FA9539, #C4E2FE);
    color: white;
    padding: 0 10px;
    width: 115px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    border: none;
    height: 25px;
}

.content .file-detail .ai-generate .ai-generate-button:active {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transform: scale(0.99);
}

.content .file-detail .document-summary {
    padding: 10px;
    background-color: #F7F7F7;
    border-radius: 10px;
}

.file-detail-perimission-list p {
    margin: 0;
    text-align: left;
    font-size: 12px;
    font-weight: bold;
    color: red;
}

.loading-box {
    display: contents;
}

.loading {
    position: relative;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-top-color: rgba(255, 255, 255, 0.2);
    border-right-color: rgba(255, 255, 255, 0.2);
    border-bottom-color: rgba(255, 255, 255, 0.2);
}

.tip {
    position: absolute;
    background-color: #DDDDDD;
    color: #666666;
    padding: 2px 10px;
    border-radius: 25px;
    z-index: 1000;
    font-size: 14px;
    max-width: 30%;
    text-align: left;
}

.tip:before {
    width: 0px;
    height: 0px;
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #DDDDDD;
    position: absolute;
    top: -10px;
    left: 50px;
}

.animated-background {
    border-radius: 10px;
    background: linear-gradient(to right,
            #71AAE7,
            #FA9539,
            #C4E2FE);
    background-size: 500% 100%;
    animation: colorChange 5s ease infinite;
}

@keyframes colorChange {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 100%;
    }
}

.generate-loader {
    height: 4px;
    width: 100%;
    --c: no-repeat linear-gradient(#F58A32 0 0);
    background: var(--c), var(--c), #DEDEDE;
    background-size: 60% 100%;
    animation: l16 3s infinite;
    margin: 35px 0;
}

@keyframes l16 {
    0% {
        background-position: -150% 0, -150% 0
    }

    66% {
        background-position: 250% 0, -150% 0
    }

    100% {
        background-position: 250% 0, 250% 0
    }
}

/* Style the tab */
.tab {
    margin: 0 auto;
    overflow: hidden;
    border: 1px solid #ccc;
    border-radius: 30px;
    background: linear-gradient(to left, #71AAE7, #FA9539, #C4E2FE);
    padding: 12px;
    display: flex;
    justify-content: space-around;
}

/* Style the buttons that are used to open the tab content */
.tab button {
    width: 40%;
    border-radius: 30px;
    background-color: #FFFFFF;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    color: #666666;
    font-weight: bold;

    &.active-tablinks {
        background-color: #E75D38;
        color: #FFFFFF;
        border: 1px solid #FFFFFF;
    }
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
    margin-top: 20px;
}

.tablinks-container{
    width:90%;
    display: flex;
    justify-content: space-around;
}

.tab-close {
    height: 26px;
    width: 26px;
    border-radius: 13px;
    background-color: #FFFFFF;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    color: #666666;
    font-weight: bold;
}

.queue-item-bar-title {
    font-size: 14px;
    color: #333334;
    font-weight: bold;
}

.queue-item-bar-body {
    width: 300px;
    height: 30px;
    border-radius: 5px;
    margin: 5px auto;
    border: 1px solid #E75D38;
    overflow: hidden;
    position: relative;
    mix-blend-mode: darken;
    font-size: 14px;
    color: #606266;
    font-weight: bold;
}

.queue-item-bar-body span {
    height: 100%;
    display: block;
    width: 0;
    color: #E75D38;
    background-color: #F7C5A6;
    line-height: 30px;
    position: absolute;
    text-align: end;
    padding-right: 5px;
    mix-blend-mode: plus-lighter;
}
</style>