import Cookies from "js-cookie";
export default {

    createHeaders() {
        return {
            'Accept': 'application/vnd.api+json',
            'Content-Type': 'application/json',
            'X-Joomla-Token': process.env.VUE_APP_X_Joomla_Token
        };
    },

    //Users
    /**
     * postLoginStatus
     * @param {number} uid
     * @param {string} sid
     */
    postLoginStatus(uid, sid) {
        const data = {
            "extra": {
                "uid": uid
                , "sid": sid
                , "action": "loginstatus"
            }
        };
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * loginStatusChecker
     * @param {number} uid
     * @param {string} sid
     * */
    async loginStatusChecker() {
        const uid = Cookies.get('uid');
        const session = Cookies.get('session');
        if (uid && session) {
            const response = await this.postLoginStatus(uid, session);
            if (response && response.data[0].attributes.extra.session) {
                return response.data[0];
            } else {
                return null;
            }
        } else {
            return null;
        }
    },

    /**
     * postLogin
     * @param {string} username
     * @param {string} password
     */
    postLogin(username, password) {
        const data = {
            "extra": {
                "username": username
                , "password": password
                , "action": "login"
            }
        };
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postLogout
     * @param {number} uid
     * @param {string} sid
     */
    postLogout(uid, sid) {
        const data = {
            "extra": {
                "uid": uid
                , "sid": sid
                , "action": "logout"
            }
        };
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * logout
     * @param {number} uid
     * @param {string} sid
     * */
    async logout() {
        const uid = Cookies.get('uid');
        const session = Cookies.get('session');
        if (uid && session) {
            const response = await this.postLogout(uid, session);
            if (response) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    },

    //Media
    /**
     * getMediaList
     */
    getMediaList() {
        return fetch(process.env.VUE_APP_BASE_URL + "/media/files/ricoh/", {
            method: 'GET',
            headers: this.createHeaders(),
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postMediaUpload
     * @param {string} name
     * @param {string} path
     * @param {number} mediatypes // 0: png, 3: pdf
     * @param {string} content // file data
     */
    postMediaUpload(name, path, mediatypes, content) {
        const data = {
            "name": name,
            "path": "ricoh/" + path,
            "override": 1,
            "mediatypes": mediatypes,
            "content": content
        };
        return fetch(process.env.VUE_APP_BASE_URL + "/media/files", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postMediaUpload
     * @param {object} data
     */
    postMediaFileUpload(data) {
        return fetch(process.env.VUE_APP_BASE_URL + "/media/files", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * getDocumentList
     * @param {Interger} uid
     * @param {String} sid
     * @param {Interger} pageNum // default 0
     * @param {Interger} pageSize // default 5
     */
    getDocumentList(pageNum = 0, pageSize = 5) {
        const uid = Cookies.get('uid');
        const sid = Cookies.get('session');

        let queryString = `?extra[action]=ricoh&ricoh[action]=docList`;
        queryString += `&extra[uid]=${uid}`;
        queryString += `&extra[sid]=${sid}`;
        queryString += `&ricoh[pageNum]=${pageNum}`;
        queryString += `&ricoh[pageSize]=${pageSize}`;

        return fetch(process.env.VUE_APP_BASE_URL + "/users" + queryString, {
            method: 'GET',
            headers: this.createHeaders(),
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postUploadFile
     * @param {object} fileData
     */
    postUploadFile(fileData) {
        let data = {
            "extra": {
                "action": "ricoh",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "ricoh": {
                "action": "fileUpload",
                "base64Content": fileData.base64Content,
                "fileType": fileData.fileType,
                "fileName": fileData.fileName,

                "fileTitle": fileData.fileTitle,
                "fileDescription": fileData.fileDescription,
                "fileAccessGroups": fileData.fileAccessGroups,
                "ossUrl": '',
                "privateFlag": fileData.privateFlag
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postQueueUpload
     * @param {object} fileData
     */
    postQueueUpload(fileData) {
        let data = {
            "extra": {
                "action": "ricoh",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "ricoh": {
                "action": "queueUpload",
                "base64Content": fileData.base64Content,
                "fileType": fileData.fileType,
                "fileName": fileData.fileName,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * getbatchuploadQueue
     * @param {object} fileData
     */
    getbatchuploadQueue(lastRefresh = '') {
        const uid = Cookies.get('uid');
        const sid = Cookies.get('session');

        let queryString = `?extra[action]=ricoh&ricoh[action]=batchUploadQueueList`;
        queryString += `&extra[uid]=${uid}`;
        queryString += `&extra[sid]=${sid}`;

        if (lastRefresh !== '') {
            queryString += `&ricoh[lastRefresh]=${lastRefresh}`;
        }

        return fetch(process.env.VUE_APP_BASE_URL + "/users" + queryString, {
            method: 'GET',
            headers: this.createHeaders(),
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * getDocumentAceessGroupList
     */
    getDocumentAceessGroupList() {
        const uid = Cookies.get('uid');
        const sid = Cookies.get('session');

        let queryString = `?extra[action]=ricoh&ricoh[action]=groupList`;
        queryString += `&extra[uid]=${uid}`;
        queryString += `&extra[sid]=${sid}`;

        return fetch(process.env.VUE_APP_BASE_URL + "/users" + queryString, {
            method: 'GET',
            headers: this.createHeaders(),
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * getDocumentDetail
     */
    getDocumentDetail(docId) {
        const uid = Cookies.get('uid');
        const sid = Cookies.get('session');

        let queryString = `?extra[action]=ricoh&ricoh[action]=docDetail`;
        queryString += `&extra[uid]=${uid}`;
        queryString += `&extra[sid]=${sid}`;
        queryString += `&ricoh[doc_id]=${docId}`

        return fetch(process.env.VUE_APP_BASE_URL + "/users" + queryString, {
            method: 'GET',
            headers: this.createHeaders(),
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postEditTtileAndDescription
     */
    postEditTtileDescription(fileData) {
        let data = {
            "extra": {
                "action": "ricoh",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "ricoh": {
                "action": "editTitleDescription",
                "doc_id": fileData.doc_id,
                "fileTitle": fileData.fileTitle,
                "fileDescription": fileData.fileDescription,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postEditAccessGroup
     */
    postEditAccessGroup(fileData) {
        let data = {
            "extra": {
                "action": "ricoh",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "ricoh": {
                "action": "editAccessGroup",
                "doc_id": fileData.doc_id,
                "privateFlag": fileData.privateFlag,
                "fileAccessGroups": fileData.fileAccessGroups,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * getDeleteDocument
     */
    getDeleteDocument(docId) {
        const uid = Cookies.get('uid');
        const sid = Cookies.get('session');

        let queryString = `?extra[action]=ricoh&ricoh[action]=docDelete`;
        queryString += `&extra[uid]=${uid}`;
        queryString += `&extra[sid]=${sid}`;
        queryString += `&ricoh[doc_id]=${docId}`

        return fetch(process.env.VUE_APP_BASE_URL + "/users" + queryString, {
            method: 'GET',
            headers: this.createHeaders(),
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postAskTitleDescription
     */
    postAskTitleDescription(prompt) {
        let data = {
            "extra": {
                "action": "ricoh",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "ricoh": {
                "action": "askTitleDescription",
                "docPrompt": prompt,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postAskAccessGroup
     */
    postAskAccessGroup(prompt) {
        let data = {
            "extra": {
                "action": "ricoh",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "ricoh": {
                "action": "askAccessGroup",
                "docPrompt": prompt,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    postSearchByPrompt(prompt,sysPrompt,saveSysPrompt) {
        let data = {};
        if (!sysPrompt) {
            data = {
                "extra": {
                    "action": "ricoh",
                    "uid": Cookies.get('uid'),
                    "sid": Cookies.get('session')
                },
                "ricoh": {
                    "action": "searchByPrompt",
                    "promptText": prompt,
                }
            }
        } else {
            data = {
                "extra": {
                    "action": "ricoh",
                    "uid": Cookies.get('uid'),
                    "sid": Cookies.get('session')
                },
                "ricoh": {
                    "action": "searchByPrompt",
                    "promptText": prompt,
                    "sysPrompt": sysPrompt,
                    "saveSysPrompt": saveSysPrompt
                }
            }
        }
        console.log(data);
        console.log(prompt,sysPrompt,saveSysPrompt);
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postChangePassword
     * @param { string } fileData.newpwd1 // new password
     * @param { string } fileData.newpwd2 // confirm new password
     */
    postChangePassword(fileData) {
        let data = {
            "extra": {
                "action": "aiuser",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "aiuser": {
                "action": "changepwd",
                "newpwd1": fileData.newpwd1,
                "newpwd2": fileData.newpwd2,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postCreateAccessGroup
     * @param { string } accessGroup
     */
    postCreateAccessGroup(accessGroup) {
        let data = {
            "extra": {
                "action": "ricoh",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "ricoh": {
                "action": "groupCreate",
                "newDept": accessGroup,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postDeleteAccessGroup
     * @param { string } accessGroup
     */
    postDeleteAccessGroup(accessGroup) {
        let data = {
            "extra": {
                "action": "ricoh",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "ricoh": {
                "action": "groupDelete",
                "delDept": accessGroup,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postCreateAIUser
     * @param { string } email
     */
    postCreateAIUser(email) {
        let data = {
            "extra": {
                "action": "aiuser",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "aiuser": {
                "action": "create",
                "name": email,
                "username": email,
                "email": email,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postSetAIUserStatus
     * @param { string } userId
     * @param { number } newStatus 
     */
    postSetAIUserStatus(userId, newStatus) {
        let data = {
            "extra": {
                "action": "aiuser",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "aiuser": {
                "action": "setuserstatus",
                "uid": userId,
                "newstatus": newStatus,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postResetPassword
     * @param { string } userId
     */
    postResetPassword(userId) {
        let data = {
            "extra": {
                "action": "aiuser",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "aiuser": {
                "action": "resetpwd",
                "uid": userId,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postSearchByTitle
     * @param { string } searchText
     */
    postSearchByTitle(searchText) {
        let data = {
            "extra": {
                "action": "ricoh",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "ricoh": {
                "action": "searchByTitle",
                "searchText": searchText,
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * getCompanyQuotaPlan
     */
    getCompanyQuotaPlan() {
        const uid = Cookies.get('uid');
        const sid = Cookies.get('session');

        let queryString = `?extra[action]=ricoh&ricoh[action]=companyQuota`;
        queryString += `&extra[uid]=${uid}`;
        queryString += `&extra[sid]=${sid}`;

        return fetch(process.env.VUE_APP_BASE_URL + "/users" + queryString, {
            method: 'GET',
            headers: this.createHeaders(),
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * postSetUserDeptLevel
     * @param { number } userId
     * @param { string } dept
     * @param { string } level
     */
    postSetUserDeptLevel(userId, dept, level) {
        let data = {
            "extra": {
                "action": "aiuser",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "aiuser": {
                "action": "setuserdeptlevel",
                "uid": userId,
                "doc_department": dept,
                "doc_level": level
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * getUserFieldsUpdateDept // edit user himself. not edit other user.
     */
    // getUserFieldsUpdateDept() {
    //     const uid = Cookies.get('uid');
    //     const sid = Cookies.get('session');

    //     let queryString = `?extra[action]=fields&fields[action]=update`;
    //     queryString += `&extra[uid]=${uid}`;
    //     queryString += `&extra[sid]=${sid}`;
    //     // queryString += `&fields[id]=${id}`; // level=7, dept=6
    //     // queryString += `&fields[name]=doc-department`;
    //     // queryString += `&fields[value]=${dept}`;

    //     return fetch(process.env.VUE_APP_BASE_URL+"/users"+queryString,{
    //         method: 'GET',
    //         headers: this.createHeaders(),
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         return data;
    //     })
    //     .catch(error => {
    //         console.error('There was an error!', error);
    //     });
    // },

    /**
     * postSetUserPrefLang
     * @param { string } preferLang
     */
    postSetUserPrefLang(preferLang) {
        let data = {
            "extra": {
                "action": "fields",
                "uid": Cookies.get('uid'),
                "sid": Cookies.get('session')
            },
            "fields": {
                "action": "update",
                "id": 12,
                "name": "ricoh-prefer-lang",
                "value": preferLang
            }
        }
        return fetch(process.env.VUE_APP_BASE_URL + "/users/extra", {
            method: 'POST',
            headers: this.createHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

    /**
     * getSignUrl
     * @param {Interger} uid
     * @param {String} sid
     * @param {Interger} doc_id
     */
    getSignUrl(doc_id) {
        const uid = Cookies.get('uid');
        const sid = Cookies.get('session');

        let queryString = `?extra[action]=ricoh&ricoh[action]=getSignUrl`;
        queryString += `&extra[uid]=${uid}`;
        queryString += `&extra[sid]=${sid}`;
        queryString += `&ricoh[doc_id]=${doc_id}`;

        return fetch(process.env.VUE_APP_BASE_URL + "/users" + queryString, {
            method: 'GET',
            headers: this.createHeaders(),
        })
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    },

}